<template>
  <div
    class="notifications-container flex-column align-end pt-12 pr-4"
    :class="{ 'd-none': items.length === 0, 'd-flex': items.length }"
  >
    <!-- Add a scrim element behind it -->
    <div
      v-if="items.length > 0"
      class="notifications-scrim"
    ></div>

    <!-- Loop through all the notification groups we have -->
    <div
      v-for="group in groups"
      :key="group.id"
      class="notification-group"
    >
      <!-- If there are more than 1 notification in this group -->
      <div
        v-if="group.items.length > 1"
        class="notification-group__header text-overline mb-1"
      >
        <v-chip
          small
          color="primary"
          close
          @click:close="destroyGroup(group)"
        >
          {{ group.items.length }} grouped notifications
        </v-chip>
      </div>

      <!-- Show the most recent notification here -->
      <notification-item
        :data="group.items[ group.items.length - 1 ]"
      />
    </div>
  </div>
</template>

<script>
// Import child components
const NotificationItem = () => import(/* webpackChunkName: "notification-item" */ "@/blocks/common/NotificationItem.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Notifications",

  // Register the components
  components: {
    NotificationItem
  },

  // Define readonly data variables
  computed: {
    /**
     * Get all the notification objects from the store
     *
     * @returns {Array}
     */
    items() {
      return this.$store.getters["notifications/get"]
    },

    /**
     * Group the notification stacks if their module, type and key matches
     *
     * @returns {Array}
     */
    groups() {
      // Create a local array to store items in groups
      const _groups = []

      // Go through each of the items
      for (const item of this.items) {
        // Check if there's already a group with these matching values
        const group = _groups.find(
          (search) =>
            search.module === item.payload.event.module &&
            search.type === item.payload.event.type &&
            search.key === item.payload.event.key
        )

        // If found
        if (group) {
          // Append the item to it
          group.items.push(item)
        }
        // Otherwise
        else {
          // Create a new group
          _groups.push({
            id: Symbol(),
            module: item.payload.event.module,
            type: item.payload.event.type,
            key: item.payload.event.key,
            items: [item]
          })
        }
      }

      // Return the final item
      return _groups
    }
  },

  // Define local method functions
  methods: {
    /**
     * Remove all items of a group from store
     *
     * @param {Object} group
     * @returns {void}
     */
    destroyGroup(group) {
      // Go through each group item
      for (const item of group.items) {
        // Remove the item
        this.$store.dispatch("notifications/remove", item.key)
      }
    }
  }
}
</script>

<style lang="stylus">
.notifications-container
  position fixed
  top 0
  right 0
  width 396px
  z-index 1001
  max-height 100vh
  overflow-y auto
  overflow-x hidden

.notifications-scrim
  position fixed
  top 0
  right 0
  width 396px
  height 100vh
  background #f4f6f8
  filter blur(4em)
  overflow hidden
</style>
